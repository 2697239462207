import React from 'react'
import {DatePicker} from '@blueprintjs/datetime'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'


export default function ZoomSidebarCalendar({selectedDay, onDayChange}) {

  return (
    <div>
      <DatePicker
        value={selectedDay}
        onChange={onDayChange}
        highlightCurrentDay={true}
      />
    </div>
  )
}
