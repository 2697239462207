import React from 'react'
import {HHMMmmLocal, DayMonDDYYYY} from '../util/dateString'


export default function ZoomCalendarAgenda({items, selectedDay}) {

  return (
    <div>
      <div
        style={{
          margin: '1em 4em',
          fontWeight: 'bold',
        }}
      >
        {DayMonDDYYYY(selectedDay)}
      </div>

      <div
        style={{
          margin: '0em 4em',
          width: '40vw',
          position: 'relative',
        }}
      >

        {[
          {time: '8:00AM', hours: 8},
          {time: '9:00AM', hours: 9},
          {time: '10:00AM', hours: 10},
          {time: '11:00AM', hours: 11},
          {time: '12:00PM', hours: 12},
          {time: '1:00PM', hours: 13},
          {time: '2:00PM', hours: 14},
          {time: '3:00PM', hours: 15},
          {time: '4:00PM', hours: 16},
          {time: '5:00PM', hours: 17},
          {time: '6:00PM', hours: 18},
          {time: '7:00PM', hours: 19},
          {time: '8:00PM', hours: 20},
          {time: '9:00PM', hours: 21},
          {time: '10:00PM', hours: 22},
        ].map((item) => (
          <ZoomAgendaHour key={item.time} time={item.time} hours={item.hours} />
        ))}

        {(items && items.map((item) => {
          let startDate = new Date(item.start_time)

          if (
            startDate.getFullYear() !== selectedDay.getFullYear()
            || startDate.getMonth() !== selectedDay.getMonth()
            || startDate.getDate() !== selectedDay.getDate()
          ) return null

          return (
            <ZoomAgendaItem
              key={item.id}
              item={item}
            />
          )
        }))}

      </div>

      <div
        style={{
          margin: '4em',
        }}
      >
        Mentors: If the meeting time that you would like to schedule for is taken, please email ceoh@pcgus.com and a Training Coordinator will set up a meeting for you.
      </div>
    </div>
  )
}


function ZoomAgendaHour({time}) {
  return (
    <div
      style={{
        border: '1px solid #aeaeae',
        position: 'relative',
        height: '4em',
      }}
    >
      <div style={{position: 'absolute', top: '2px', left: '2px', color: '#888'}}>{time}</div>

    </div>
  )
}


function ZoomAgendaItem({item}) {

  const startTime = new Date(item.start_time)
  const duration = item.duration
  const endTime = new Date(item.start_time)
  endTime.setMinutes(endTime.getMinutes() + duration)

  return (
    <div
      style={{
        position: 'absolute',
        top: `${(startTime.getHours() - 8 + (startTime.getMinutes() / 60)) * 4}em`,
        left: '8em',
        minWidth: '10em',
        maxWidth: '40vw',
        height: `${(duration / 60) * 4}em`,
        fontWeight: 'bold',
        border: '1px solid #888',
        backgroundColor: 'rgba(180, 230, 255, 0.5)',
        padding: '0.2em',
      }}
    >
      {item.topic}
      <div style={{fontWeight: 'normal', fontSize: '0.8em'}}>
        {HHMMmmLocal(startTime)}
        {' - '}
        {HHMMmmLocal(endTime)}
      </div>
    </div>
  )
}
