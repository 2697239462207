import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import sessionManager from '../util/sessionManager'
import socketManager from '../util/socketManager'
import useSiteMetadata from '../util/useSiteMetadata'
import classNames from 'classnames'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ZoomSidebarCalendar from '../components/ZoomSidebarCalendar'
import ZoomCalendarAgenda from '../components/ZoomCalendarAgenda'
import ZoomCreateMeetingDialog from '../components/ZoomCreateMeetingDialog'
import { Button } from '@blueprintjs/core'


export default function ZoomPage() {
  const [global, setGlobal] = useState(sessionManager.getState())
  const dispatch = sessionManager.dispatch
  const siteMeta = useSiteMetadata()


  useEffect(function () {
    return sessionManager.subscribe(function () {
      setGlobal(sessionManager.getState())
    })
  }, [])


  // ! TEMP:
  const [selectedDay, setSelectedDay] = useState(new Date())
  const [zoomMeetings, setZoomMeetings] = useState(false)
  const [addingMeeting, setAddingMeeting] = useState(false)
  const [newMeetingTopic, setNewMeetingTopic] = useState('')
  const [newMeetingAgenda, setNewMeetingAgenda] = useState('')
  const [newMeetingStartTime, setNewMeetingStartTime] = useState(new Date())
  const [newMeetingDuration, setNewMeetingDuration] = useState(60)
  const [createdMeetingId, setCreatedMeetingId] = useState(null)
  const [createdMeetingHostLink, setCreatedMeetingHostLink] = useState(null)
  const [createdMeetingJoinLink, setCreatedMeetingJoinLink] = useState(null)
  const [viewingMeeting, setViewingMeeting] = useState(false)
  const [newJoinUrl, setNewJoinUrl] = useState(false)
  const [newStartUrl, setNewStartUrl] = useState(false)

  useEffect(function () {
    socketManager.uiGet('listZoomMeetings', { zoom_user_id: 'gpKqnm2VSxSCP8kheu_bJg' }, function (resData) {
      const meetingsRes = resData.zoom_meetings.meetings
      setZoomMeetings(meetingsRes)
    })
  }, [])


  function handleNewClick() {
    setAddingMeeting(true)
  }

  function doneAdding(newMeeting) {
    setAddingMeeting(false)

    if (newMeeting) {
      setViewingMeeting(newMeeting.id)
      setNewJoinUrl(newMeeting.join_url)
      setNewStartUrl(newMeeting.start_url)
    }
    socketManager.uiGet('listZoomMeetings', { zoom_user_id: 'gpKqnm2VSxSCP8kheu_bJg' }, function (resData) {
      const meetingsRes = resData.zoom_meetings.meetings
      setZoomMeetings(meetingsRes)
    })
  }

  function handleSelectedDayChange(newDate) {
    if (!newDate) return
    setSelectedDay(newDate)
  }

  function handleNewMeetingTopicChange(e) {
    let value = e.target.value
    setNewMeetingTopic(value)
  }

  function handleNewMeetingAgendaChange(e) {
    let value = e.target.value
    setNewMeetingAgenda(value)
  }

  function handleNewMeetingStartTimeChange(newDate) {
    setNewMeetingStartTime(newDate)
  }

  function handleNewMeetingDurationChange(newVal) {
    setNewMeetingDuration(newVal)
  }



  function maybeRenderNewMeetingDialog() {
    if (addingMeeting) {
      return (
        <ZoomCreateMeetingDialog
          global={global}
          dispatch={dispatch}
          siteMeta={siteMeta}
          doneAdding={doneAdding}
          zoomMeetings={zoomMeetings}
          newMeetingTopic={newMeetingTopic}
          newMeetingAgenda={newMeetingAgenda}
          newMeetingStartTime={newMeetingStartTime}
          newMeetingDuration={newMeetingDuration}
          createdMeetingId={createdMeetingId}
          createdMeetingHostLink={createdMeetingHostLink}
          createdMeetingJoinLink={createdMeetingJoinLink}
          onTopicChange={handleNewMeetingTopicChange}
          onAgendaChange={handleNewMeetingAgendaChange}
          onStartTimeChange={handleNewMeetingStartTimeChange}
          onDurationChange={handleNewMeetingDurationChange}
        />
      )
    }
  }



  if (!global.current_user_vuid) {
    navigate('/mentors')
    return null
  }


  return (
    <main>
      <title>Mentors</title>

      <NavBar global={global} dispatch={dispatch} />
      <div className='page-heading'>
        <Container>
          <h1>Zoom Meetings</h1>
        </Container>
      </div>

      <Container>
        <Row>
        
          <Col xs={6} md={4}>

              <Button
                text='Schedule New Meeting'
                onClick={handleNewClick}
                icon='plus'
                intent='primary'
              />
                <hr/>
              <ZoomSidebarCalendar
                selectedDay={selectedDay}
                onDayChange={handleSelectedDayChange}
              />
            <hr />
          <dl>
              <dt>Join Zoom Meeting</dt>
              <dd><a href='https://us06web.zoom.us/j/2578232523?pwd=cWNQZzlKMzJvUzJib1hCTks4QVg3Zz09'>https://us06web.zoom.us/j/2578232523?pwd=cWNQZzlKMzJvUzJib1hCTks4QVg3Zz09</a></dd>

              <dt>Meeting ID:</dt>
              <dd>257 823 2523</dd>

              <dt>Passcode:</dt>
              <dd>348834</dd>

              {/* <dt>One tap mobile</dt>
              <dd>+13017158592,,2578232523#,,,,*348834# US (Washington DC)</dd> */}

              <dt>Dial by your location</dt>
              <dd><a href='tel:13017158592'>1-301-715-8592 US (Washington DC)</a></dd>
              <dt>Meeting ID:</dt>
              <dd>257 823 2523</dd>

              <dt>Passcode:</dt>
              <dd>348834</dd>

              <dt>Find your local number:</dt>
              <dd><a href='https://us06web.zoom.us/u/kcny0ezt8h'>https://us06web.zoom.us/u/kcny0ezt8h</a></dd>
          </dl>

            
          </Col>

          <Col xs={12} md={8}>
              <div className="text-center">All times in EST</div>
              <ZoomCalendarAgenda
                items={zoomMeetings}
                selectedDay={selectedDay}
              />
          
          </Col>
          
        </Row>
          {maybeRenderNewMeetingDialog()}

      </Container>

      <footer className='footer'>
        <Footer />
      </footer>
    </main>
  )
}
