import React, {useState, useEffect, useRef} from 'react'
import {Dialog, Button, Intent, Classes, InputGroup, TextArea, NumericInput, Card, Callout} from '@blueprintjs/core'
import {DateInput, TimePicker} from '@blueprintjs/datetime'
import socketManager from '../util/socketManager'
import {IsoUtc} from '../util/dateString'


function formatDate(d) {
  let YYYY = d.getFullYear()
  let MM = (`0${(d.getMonth() + 1)}`).slice(-2)
  let DD = (`0${d.getDate()}`).slice(-2)

  return `${MM}/${DD}/${YYYY}`
}


function parseDate(str) {
  const split = str.split('/')
  return new Date(`${split[2]}-${split[0]}-${split[1]}`)
}

export default function ZoomCreateMeetingDialog({
  global,
  dispatch,
  siteMeta,
  doneAdding,
  zoomMeetings,
  newMeetingTopic,
  newMeetingAgenda,
  newMeetingStartTime,
  newMeetingDuration,
  createdMeetingId,
  createdMeetingHostLink,
  createdMeetingJoinLink,
  onTopicChange,
  onAgendaChange,
  onStartTimeChange,
  onDurationChange,
}) {


  const [spin, setSpin] = useState(false)
  const initFocus = useRef(null)

  const newMeetingEndTime = new Date(newMeetingStartTime)
  newMeetingEndTime.setMinutes(newMeetingEndTime.getMinutes() + newMeetingDuration)

  function dialogDidOpen() {
    if (initFocus.current) {
      initFocus.current.focus()
    }
  }


  function handleStartDayChange(date) {
    let newDate = new Date(date)
    newDate.setHours(newMeetingStartTime.getHours(), newMeetingStartTime.getMinutes(), 0)
    onStartTimeChange(newDate)
  }

  function handleStartTimeChange(date) {
    let newDate = new Date(newMeetingStartTime)
    newDate.setHours(date.getHours(), date.getMinutes(), 0)
    onStartTimeChange(newDate)
  }

  function handleEndTimeChange(date) {
    let newDate = new Date(newMeetingEndTime)
    newDate.setHours(date.getHours(), date.getMinutes(), 0)
    let diff = newDate.getTime() - newMeetingStartTime.getTime()
    onDurationChange(Math.round(diff / 60000))
  }

  function handleDurationChange(newVal) {
    onDurationChange(Math.trunc(newVal))
  }

  function checkConflicts() {
    for (let meeting of zoomMeetings) {
      let meetingStart = new Date(meeting.start_time)
      let meetingEnd = new Date(meeting.start_time)
      meetingEnd.setMinutes(meetingEnd.getMinutes() + meeting.duration)

      if (
        newMeetingStartTime < meetingEnd
        && newMeetingEndTime > meetingStart
      ) {
        return true
      }
    }

    return false
  }

  function maybeRenderConflictCallout() {
    if (checkConflicts()) {
      return (
        <div>
          <Callout intent='danger'>
            The date and time you have selected conflicts with another scheduled meeting
          </Callout>
          <div style={{margin: '1em 0em'}}>
            Mentors: If the meeting time that you would like to schedule for is taken, please email ceoh@pcgus.com and a Training Coordinator will set up a meeting for you.
          </div>
        </div>
      )
    }
  }


  function handleSaveClick() {

    if (!newMeetingTopic) {
      return
    }

    if (!newMeetingStartTime) {
      return
    }

    if (!newMeetingDuration) {
      return
    }

    if (checkConflicts()) {
      return
    }

    setSpin(true)

    console.log('-------')
    console.log(newMeetingStartTime)
    console.log(IsoUtc(newMeetingStartTime))


    socketManager.uiAction('createZoomMeeting', {
      zoom_user_id: siteMeta.zoom_user_id,
      topic: newMeetingTopic,
      agenda: newMeetingAgenda,
      start_time: IsoUtc(newMeetingStartTime),
      duration: newMeetingDuration,
    }, function (resData) {

      // TODO: handle errors...

      console.log(resData)

      doneAdding({
        id: resData.new_meeting.id,
        join_url: resData.new_meeting.join_url,
        start_url: resData.new_meeting.start_url,
      })
    })
  }

  function handleCancelClick() {
    doneAdding()
  }

  function getButtonState() {

    // TODO...

    return true
  }

  return (
    <Dialog
      isOpen={true}
      className={Classes.DIALOG_BODY}
      onOpened={dialogDidOpen}
      style={{
        position: 'relative',
        minHeight: '12rem',
        flex: '0 1 auto',
        width: 'unset',
        minWidth: '40vw',
        maxWidth: '80vw',
      }}
    >
      <div
        className={Classes.DIALOG_HEADER}
        style={{
          fontSize: '0.8rem',
        }}
      >
				SCHEDULE A NEW MEETING
      </div>

      <div
        style={{
          margin: '0em 2em 4em 2em',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
        }}
      >

        <div
          style={{
            marginRight: '1em',
            paddingRight: '1em',
            borderRight: '1px solid #c4c4c4',
          }}
        >

          <div
            style={{
              margin: '1em',
            }}
          >
            <div style={{fontSize: '0.9em', color: '#222'}}>DATE &amp; TIME:</div>
            <div>
              <DateInput
                value={newMeetingStartTime}
                formatDate={formatDate}
                parseDate={parseDate}
                onChange={handleStartDayChange}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
            }}
          >
            <div style={{flex: '1 0 auto', margin: '1em'}}>
              <div style={{fontSize: '0.85em'}}>START TIME</div>
              <Card elevation={2} style={{padding: '0em 1em'}}>
                <TimePicker
                  value={newMeetingStartTime}
                  onChange={handleStartTimeChange}
                  useAmPm={true}
                  showArrowButtons={true}
                />
              </Card>
            </div>

            <div style={{flex: '1 0 auto', margin: '1em'}}>
              <div style={{fontSize: '0.85em'}}>END TIME</div>
              <Card elevation={2} style={{padding: '0em 1em'}}>
                <TimePicker
                  value={newMeetingEndTime}
                  onChange={handleEndTimeChange}
                  useAmPm={true}
                  showArrowButtons={true}
                />
              </Card>
            </div>

            <div style={{flex: '1 0 auto', margin: '1em'}}>
              <div style={{fontSize: '0.85em'}}>DURATION</div>
              <Card elevation={2} style={{padding: '1em'}}>
                <NumericInput
                  value={newMeetingDuration}
                  onValueChange={handleDurationChange}
                  min={0}
                  stepSize={15}
                  fill={false}
                  rightElement={(
                    <div style={{margin: '0.5em', color: '#8a9ba8', pointerEvents: 'none'}}>min</div>
                  )}
                  style={{
                    maxWidth: '6em',
                  }}
                />
              </Card>
            </div>
          </div>

          {maybeRenderConflictCallout()}

        </div>

        <div style={{

        }}>

          <div
            style={{
              margin: '1em',
            }}
          >
            <div style={{fontSize: '0.9em', color: '#222'}}>TEAM NAME:</div>
            <div>
              <InputGroup
                value={newMeetingTopic}
                onChange={onTopicChange}
                fill={true}
                inputRef={initFocus}
                style={{
                  minWidth: '18em',
                }}
              />
            </div>
          </div>



          <div
            style={{
              margin: '1em',
            }}
          >
            <div style={{fontSize: '0.9em', color: '#222'}}>NOTES / AGENDA:</div>
            <div>
              <TextArea
                value={newMeetingAgenda}
                onChange={onAgendaChange}
                fill={true}
                style={{
                  minHeight: '6em',
                }}
              />
            </div>
          </div>





        </div>

      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '0px',
          left: '0px',
          right: '0px',
          textAlign: 'right',
          padding: '12px',
        }}
      >
        <Button
          text='cancel'
          small={true}
          minimal={true}
          onClick={handleCancelClick}
        />

        &nbsp;&nbsp;

        <Button
          text='Create'
          intent={Intent.PRIMARY}
          onClick={handleSaveClick}
          loading={spin}
          disabled={!getButtonState()}
        />
      </div>
    </Dialog>
  )
}
