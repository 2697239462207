export function MonDDYYYY(raw) {
  if (!raw) return ''
  var d = new Date(raw)

  var Mon = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ][d.getMonth()]

  var YYYY = d.getFullYear()
  var maybeYYYY = (YYYY === new Date().getFullYear()) ? '' : (` ${YYYY}`)

  return `${Mon} ${d.getDate()}${maybeYYYY}`
}



export function DayMonDDYYYY(raw) {
  if (!raw) return ''
  var d = new Date(raw)

  var Day = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ][d.getDay()]

  return `${Day}, ${MonDDYYYY(raw)}`
}



export function intervalDays(duration) {
  const DAY = 86400000
  const HOUR = 3600000

  var days = Math.trunc(duration / DAY)
  var residual = duration % DAY
  var hours = Math.trunc(residual / HOUR)

  var maybeDays = ''
  var maybeHours = ''

  if (days > 1) {
    maybeDays = `${days} days`
  }
  else if (days === 1) {
    maybeDays = `${days} day`
  }

  if (hours > 1) {
    maybeHours = `${hours} hours`
  }
  else if (hours === 1) {
    maybeHours = `${hours} hour`
  }

  return maybeDays + ((maybeDays && maybeHours) ? ' ' : '') + maybeHours
}



export function intervalSeconds(duration) {
  const DAY = 86400000
  const HOUR = 3600000
  const MINUTE = 60000
  const SECOND = 1000

  const days = Math.trunc(duration / DAY)

  const residualHours = duration % DAY
  const hours = Math.trunc(residualHours / HOUR)

  const residualMinutes = residualHours % HOUR
  const minutes = Math.trunc(residualMinutes / MINUTE)

  const residualSeconds = residualMinutes % MINUTE
  const seconds = Math.trunc(residualSeconds / SECOND)

  let maybeDays = ''
  let maybeHours = ''
  let maybeMinutes = ''
  let maybeSeconds = ''


  if (days > 1) {
    maybeDays = `${days} days`
  }
  else if (days === 1) {
    maybeDays = `${days} day`
  }


  if (hours > 1) {
    maybeHours = `${hours} hours`
  }
  else if (hours === 1) {
    maybeHours = `${hours} hour`
  }


  if (minutes > 1) {
    maybeMinutes = `${minutes} minutes`
  }
  else if (minutes === 1) {
    maybeMinutes = `${minutes} minute`
  }


  if (seconds > 1) {
    maybeSeconds = `${seconds} seconds`
  }
  else if (seconds === 1) {
    maybeSeconds = `${seconds} second`
  }


  return `${maybeDays}${(maybeDays && maybeHours) ? ' ' : ''}${maybeHours}${(maybeHours && maybeMinutes) ? ' ' : ''}${maybeMinutes}${(maybeMinutes && maybeSeconds) ? ' ' : ''}${maybeSeconds}`

}



export function intervalFromNow(epoch) {
  let diff = Date.now() - epoch
  let interval = intervalSeconds(diff)

  return interval ? `${interval} ago` : 'now'
}



export function browserLocale(epoch) {
  if (!epoch) return ''
  return new Date(epoch).toLocaleString().replace(/,.*$/, '')
}



export function IsoUtc(input) {
  if (!input) return ''
  const d = new Date(input)

  const DD = (`0${d.getUTCDate()}`).slice(-2)
  const MM = (`0${d.getUTCMonth() + 1}`).slice(-2)
  const YYYY = d.getUTCFullYear()
  const hh = (`0${d.getUTCHours()}`).slice(-2)
  const mm = (`0${d.getUTCMinutes()}`).slice(-2)


  return `${YYYY}-${MM}-${DD}T${hh}:${mm}:00Z`
}



export function IsoEst(input) {
  if (!input) return ''
  const d = new Date(input)
  let offsetString

  if (+MM < 11 && +MM > 3) {
    offsetString = '0400'
    d.setHours(d.getHours() - 4)
  }
  else {
    offsetString = '0500'
    d.setHours(d.getHours() - 5)
  }

  const DD = (`0${d.getUTCDate()}`).slice(-2)
  const MM = (`0${d.getUTCMonth() + 1}`).slice(-2)
  const YYYY = d.getUTCFullYear()
  const hh = (`0${d.getUTCHours()}`).slice(-2)
  const mm = (`0${d.getUTCMinutes()}`).slice(-2)


  return `${YYYY}-${MM}-${DD}T${hh}:${mm}:00-${offsetString}`
}



export function IsoLocal(input) {
  if (!input) return ''
  const d = new Date(input)

  const DD = (`0${d.getDate()}`).slice(-2)
  const MM = (`0${d.getMonth() + 1}`).slice(-2)
  const YYYY = d.getFullYear()
  const hh = (`0${d.getHours()}`).slice(-2)
  const mm = (`0${d.getMinutes()}`).slice(-2)
  const offsetString = (`0${(d.getTimezoneOffset() / 60)}00`).slice(-4)


  return `${YYYY}-${MM}-${DD}T${hh}:${mm}:00-${offsetString}`
}



export function HHMMmmLocal(input) {
  if (!input) return ''
  const d = new Date(input)

  const fullHours = d.getHours()
  const fullMinutes = d.getMinutes()
  let hh
  let mm
  let merString

  if (fullHours < 13) {
    hh = fullHours
    mm = (`0${fullMinutes}`).slice(-2)
    merString = 'AM'
  }
  else {
    hh = fullHours - 12
    mm = (`0${fullMinutes}`).slice(-2)
    merString = 'PM'
  }

  return `${hh}:${mm} ${merString}`
}
