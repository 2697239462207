import {useStaticQuery, graphql} from 'gatsby'

		// oauth_client_id
					// oauth_authorize_url
					// oauth_logout_url
					
export default function useSiteMetadata() {
  const data = useStaticQuery(graphql`
		query SiteMetaData {
			site {
				siteMetadata {
					title
					deployment_id
					deployment_name_long
					deployment_name_short
					portal_id
					gateway
					pf_path
			
					zoom_user_id
				}
			}
		}
	`)

  return data.site.siteMetadata
}
